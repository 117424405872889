import './EditorChoice.css'; // Custom CSS for additional styling

import { Link } from 'react-router-dom'; // Import Link for navigation
import React from 'react';

const EditorChoice = () => {
  return (
    <div className="container my-5" id='contact'>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="contact-title">Contact Us</h2>
      </div>

      {/* Contact Info Section */}
      <div className="row mb-5">
        <div className="col-md-6">
          <h4>Our Office</h4>
          <p>
            Kerawalapitiya <br />
            Mahabage , Ragama<br />
            Email: Lahirusupunhc@gmail.com<br />
            Phone: +94 776151943
          </p>
        </div>

        <div className="col-md-6">
          <h4>Business Hours</h4>
          <p>
            Monday - Friday: 9:00 AM - 6:00 PM<br />
            Saturday: 10:00 AM - 4:00 PM<br />
            Poya Days: Closed
          </p>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="row">
        <div className="col-md-12">
          <h4>Send Us a Message</h4>
          <form className="contact-form">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Full Name</label>
              <input type="text" className="form-control" id="name" placeholder="Your name" />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email Address</label>
              <input type="email" className="form-control" id="email" placeholder="Your email" />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Your Message</label>
              <textarea className="form-control" id="message" rows="4" placeholder="Your message"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Send Message</button>
          </form>
        </div>
      </div>

      <div className="text-end mt-3">
        <Link to="/" className="back-to-home-link">
          Back to Home →
        </Link>
      </div>
    </div>
  );
};

export default EditorChoice;
