import H1 from './H1.jpeg'
import H10 from './H10.png'
import H11 from './H11.webp'
import H2 from './H2.webp'
import H3 from './H3.jpg'
import H4 from './H4.jpg'
import H5 from './H5.jpg'
import H6 from './H6.png'
import H7 from './H7.jpg'
import H8 from './H8.jpg'
import H9 from './H9.png'
import MITLogo from './MIT Logo.png'
import admin from './admin.png'
import c1 from './c1.jpg'
import c2 from './c20.jpg'
import c3 from './c21.jpg'
import c4 from './c25.jpg'
import email from './email.png'
import expo from './Expo.png'
import facebook_icon from './facebook_icon.png'
import linkedin_icon from './linkedin_icon.png'
import phone from './phone.png'
import td from './td.png'
import td2 from './td2.png'
import td3 from './td.jpeg'
import ts from './ts.jpg'
import twitter_icon from './twitter_icon.png'
import up from './up.png'

export const  assest = {
    expo,MITLogo,email,phone,facebook_icon,twitter_icon,linkedin_icon,td,H1,H2,H3,td2,up,admin,H5,H4,H6,H7,H8,H9,H10,H11,td3,ts,c1,c2,c3,c4
}

