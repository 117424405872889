import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RecentPosts.css'; // Keeping the same CSS file

import React, { useCallback, useEffect, useRef, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Slider from 'react-slick';
import { assest } from '../../assest/assest';

// Random testimonial data for testing
const testimonialsData = [
  {
    image: 'https://via.placeholder.com/100x100/FF5733/FFFFFF?text=John+Doe',
    name: 'John Doe',
    title: 'CEO, Company A',
    testimonial: 'This product changed my life! The best investment I made this year.',
  },
  {
    image: 'https://via.placeholder.com/100x100/33FF57/FFFFFF?text=Jane+Smith',
    name: 'Jane Smith',
    title: 'Marketing Director, Company B',
    testimonial: 'Highly recommend this to anyone looking to improve their business strategy.',
  },
  {
    image: 'https://via.placeholder.com/100x100/5733FF/FFFFFF?text=Michael+Johnson',
    name: 'Michael Johnson',
    title: 'Founder, Startup C',
    testimonial: 'A game-changer! The results were immediate, and I’m extremely satisfied.',
  },
  {
    image: 'https://via.placeholder.com/100x100/FFC300/FFFFFF?text=Emily+Clark',
    name: 'Emily Clark',
    title: 'Product Manager, Company D',
    testimonial: 'Absolutely incredible service. My team has seen remarkable improvements.',
  },
  {
    image: 'https://via.placeholder.com/100x100/900C3F/FFFFFF?text=David+Lee',
    name: 'David Lee',
    title: 'Engineer, Company E',
    testimonial: 'The customer support is fantastic! I had a seamless experience from start to finish.',
  },
];

function Testimonial() {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="gallery-section" id="testimonial" style={{ position: 'relative', padding: '40px 0', color: '#fff' }}>
      {/* Background image and dark overlay */}
      <div
        className="background-image"
        style={{
          backgroundImage: `url(https://t4.ftcdn.net/jpg/02/02/17/99/360_F_202179910_Uv0HNNaT1LklpUAwfC5eJY3QhGL2IkAc.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      />
      <div
        className="dark-overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
          zIndex: 1, // Ensure it's above the background image
        }}
      />
      <Container style={{ position: 'relative', zIndex: 2 }}>
        <h2 className="gallery-heading">What Our Students Say</h2>
        <Slider ref={sliderRef} {...settings}>
          {testimonialsData.map((testimonial, idx) => (
            <div key={idx} className="slide">
              <div className="testimonial-card">
                <div className="testimonial-image">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                  />
                </div>
                <div className="testimonial-content">
                  <h4 className="testimonial-name">{testimonial.name}</h4>
                  <p className="testimonial-title">{testimonial.title}</p>
                  <p className="testimonial-text">"{testimonial.testimonial}"</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
}

export default Testimonial;
