import './Header.css'; // Add custom CSS for styling

import { Carousel, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import React from 'react';
import { useArticles } from '../Contex/ArticlesContext';

function Header() {
  const { getArticlesBySection } = useArticles(); // Get function from context
  const topPosts = getArticlesBySection('top'); // Grab articles for the "top" section

  return (
    <Container fluid className="p-0">
      <Carousel className="header-carousel" interval={2000} controls={true} indicators={true}>
        {topPosts.map((post, index) => (
          <Carousel.Item key={index}>
             <Link  >
                  
               
            <img
              className="d-block w-100 carousel-image"
              src={post.imageUrl}
              alt={`Slide ${index + 1}`}
            />
             </Link>
          
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
}

export default Header;
