import "bootstrap/dist/css/bootstrap.min.css";
import "./FeaturedPosts.css";

import React, { useEffect, useState } from "react";

import { assest } from "../../assest/assest";

const FeaturedPosts = () => {
  // Updated data for the About section
  const tutorInfo = {
    name: "T.M. Lahiru Supun",
    bio: `Hello! I'm T.M. Lahiru Supun, a dedicated tutor with 3 years of experience in helping students excel in various subjects. I have studied at Horizon Campus as a graduate with a degree in Human Resource Management (HRM) and have completed a Diploma in Business Management from the London Campus. I believe in a personalized approach to teaching, making learning fun and accessible for everyone. Whether it's exam preparation or improving your grades, I am here to help you succeed.`,
    imageUrl: assest.td3,
    experience: "3 years of tutoring experience",
    specialties: "HRM, Business Management, English",
    contact: "Lahirusupunhc@gmail.com",
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect mobile screen

  // Handle resize event for responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update state based on screen size
    };

    window.addEventListener("resize", handleResize); // Listen to resize events
    return () => window.removeEventListener("resize", handleResize); // Clean up the listener
  }, []);

  return (
    <div className="container my-5" id="lahiru">
      <div className="row align-items-center">
        {/* Left Column: Tutor Image */}
        <div
          className={`col-md-6 ${isMobile ? "d-flex justify-content-center" : ""}`}
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-start", // Center image on mobile
            alignItems: "center", // Vertically center image
          }}
        >
          <img
            src={tutorInfo.imageUrl}
            alt="Tutor"
            className="img-fluid rounded-circle"
            style={{
              maxWidth: isMobile ? "60%" : "70%", // Smaller on mobile
              height: "auto",
            }}
          />
        </div>

        {/* Right Column: Tutor About Info */}
        <div
          className={`col-md-6 ${isMobile ? "left" : ""}`} // Center text on mobile
          style={{
            textAlign: "left", // Center text on mobile
          }}
        >
          <h2 className="about-title" style={{ textAlign: isMobile ? "center" : "left" }}>
            {tutorInfo.name}
          </h2>
          <p className="about-bio">{tutorInfo.bio}</p>
          <div className="about-info">
            <p><strong>Experience:</strong> {tutorInfo.experience}</p>
            <p><strong>Specialties:</strong> {tutorInfo.specialties}</p>
            <p><strong>Contact:</strong> <a href={`mailto:${tutorInfo.contact}`}>{tutorInfo.contact}</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedPosts;
