import "bootstrap/dist/css/bootstrap.min.css";
import "./TopStories.css"; // Custom CSS file for additional styling

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom"; // Import Link for navigation
import { assest } from "../../assest/assest";

const TopStories = () => {
  // State to manage the services
  const [services, setServices] = useState({
    groupLessons: [],
    specialClasses: [],
    revisions: [],
  });

  useEffect(() => {
    // Sample service data for different sections
    const servicesData = {
      groupLessons: [
        {
          id: 1,
          title: "Group Commerce Lessons for Grade 10",
          description:
            "Join our interactive group lessons where we cover the fundamentals of commerce, including economics, accounting, and business studies.",
          imageUrl: assest.c1, // Placeholder for image
          category: "Group Lessons - Grade 10",
        },
        {
          id: 2,
          title: "Group Commerce Lessons for Grade 11",
          description:
            "Our group lessons for Grade 11 focus on advanced concepts in business studies, including economics, accounting, and revision for exams.",
          imageUrl: assest.c2, // Placeholder for image
          category: "Group Lessons - Grade 11",
        },
      ],
      specialClasses: [
        {
          id: 3,
          title: "Special Classes in Accounting",
          description:
            "Get one-on-one special classes in accounting to boost your knowledge and improve exam results. Tailored to meet your needs.",
          imageUrl: assest.H7, // Placeholder for image
          category: "Special Classes",
        },
      ],
      revisions: [
        {
          id: 4,
          title: "Revision Classes for Grade 10 Commerce",
          description:
            "Prepare for your exams with our revision classes, focusing on key topics for Grade 10 students in commerce.",
          imageUrl: assest.c3, // Placeholder for image
          category: "Revision - Grade 10",
        },
        {
          id: 5,
          title: "Revision Classes for Grade 11 Commerce",
          description:
            "Our revision sessions for Grade 11 commerce students focus on core subjects to ensure you are exam-ready.",
          imageUrl: assest.c4, // Placeholder for image
          category: "Revision - Grade 11",
        },
      ],
    };

    // Update state with the service data
    setServices(servicesData);
  }, []);

  return (
    <div className="container my-5" id="services">
      <div className="text-center mb-4">
        <h2 className="top-stories-title">Commerce Tutoring Services</h2>
      </div>

      {/* Group Lessons Section */}
      <div className="mb-5">
        <h3>Group Lessons</h3>
        <div className="row">
          {services.groupLessons.length > 0 ? (
            services.groupLessons.map((service) => (
              <div key={service.id} className="col-md-6 mb-4">
                <div className="card story-card h-100">
                  <Link to={`/service/${service.id}`}>
                    <div
                      className="story-image"
                      style={{ backgroundImage: `url(${service.imageUrl})` }}
                    >
                      <span className="story-category badge bg-success">
                        {service.category}
                      </span>
                    </div>
                  </Link>
                  <div className="card-body">
                    <h5 className="card-title">
                      <Link
                        to={`/service/${service.id}`}
                        className="text-decoration-none"
                        style={{ color: "black" }}
                      >
                        {service.title}
                      </Link>
                    </h5>
                    <p className="card-text">{service.description}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No group lessons available.</p>
          )}
        </div>
      </div>

      {/* Special Classes Section */}
      <div className="mb-5">
        <h3>Special Classes</h3>
        <div className="row">
          {services.specialClasses.length > 0 ? (
            services.specialClasses.map((service) => (
              <div key={service.id} className="col-md-6 mb-4">
                <div className="card story-card h-100">
                  <Link to={`/service/${service.id}`}>
                    <div
                      className="story-image"
                      style={{ backgroundImage: `url(${service.imageUrl})` }}
                    >
                      <span className="story-category badge bg-info">
                        {service.category}
                      </span>
                    </div>
                  </Link>
                  <div className="card-body">
                    <h5 className="card-title">
                      <Link
                        to={`/service/${service.id}`}
                        className="text-decoration-none"
                        style={{ color: "black" }}
                      >
                        {service.title}
                      </Link>
                    </h5>
                    <p className="card-text">{service.description}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No special classes available.</p>
          )}
        </div>
      </div>

      {/* Revision Classes Section */}
      <div className="mb-5">
        <h3>Revision Classes</h3>
        <div className="row">
          {services.revisions.length > 0 ? (
            services.revisions.map((service) => (
              <div key={service.id} className="col-md-6 mb-4">
                <div className="card story-card h-100">
                  <Link to={`/service/${service.id}`}>
                    <div
                      className="story-image"
                      style={{ backgroundImage: `url(${service.imageUrl})` }}
                    >
                      <span className="story-category badge bg-warning">
                        {service.category}
                      </span>
                    </div>
                  </Link>
                  <div className="card-body">
                    <h5 className="card-title">
                      <Link
                        to={`/service/${service.id}`}
                        className="text-decoration-none"
                        style={{ color: "black" }}
                      >
                        {service.title}
                      </Link>
                    </h5>
                    <p className="card-text">{service.description}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No revision classes available.</p>
          )}
        </div>
      </div>

      {/* "See All Services" Link */}
      <div className="text-end mt-3">
        <Link to="/services" className="see-all-posts text-decoration-none">
          See All Services →
        </Link>
      </div>
    </div>
  );
};

export default TopStories;
